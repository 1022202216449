import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  WORDS.forEach((ww, i) => {
    WORDS[i] = ww.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  });
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution.normalize('NFD').replace(/[\u0300-\u036f]/g, "") === word
}

export const getWordOfDay = () => {
  const index = Math.round(Math.random() * WORDS.length)
  console.log(WORDS[index].toUpperCase());
  return {
    solution: WORDS[index].toUpperCase(),
    solutionIndex: index,
  }
}

export const { solution, solutionIndex } = getWordOfDay()
